import React from 'react'

import {
  Bullet,
  Button,
  Buttons,
  Columns,
  Divider,
  FormGroup,
  FormLayout,
  FormLayoutItem,
  Heading,
  List,
  ListItem,
  Main,
  Message,
  Paragraph,
  Spacer,
  Textarea,
  ThumbDownIcon,
  ThumbUpIcon,
  XIcon
} from '@te-digi/styleguide'

import { Content } from '../../components/Content'
import { NoteItem } from '../../components/NoteItem'
import { PatternExampleJulkaise } from '../../examples/react/PatternExampleJulkaise'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import { ZoomedExample } from '../../components/ZoomedExample'
import { Link } from '../../components/Link'
import { Playground } from '../../components/Playground'
import { Code } from '../../components/Code'
import { PatternExampleFailJulkaise } from '../../examples/react/PatternExampleToimintoFail'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content heading="Julkaise-toiminto">
    <Playground
      enableOverflow
      example={PatternExampleJulkaise}
    />
    <Section title="Käyttötarkoitukset">
      <Message attentionColor="success">
        <Heading
          icon={
            <ThumbUpIcon
              color="success"
              size="lg"
            />
          }
          level={3}
          size={5}
        >
          Käytetään
        </Heading>
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            <strong>Lomakkeilla</strong>, jos käyttäjä julkaisee lähetettävät
            tiedot Työmarkkinatorilla yleisesti nähtäväksi (esim.
            työpaikkailmoitus tai palvelu).
          </ListItem>
          <ListItem>
            <strong>Katselunäkymissä</strong>, jos käyttäjä julkaisee
            katseltavat tiedot muiden käyttäjien nähtäväksi (esim.
            työnhakuprofiili).
          </ListItem>
        </List>
      </Message>
      <Message attentionColor="danger">
        <Heading
          icon={
            <ThumbDownIcon
              color="danger"
              size="lg"
            />
          }
          level={3}
          size={5}
        >
          Ei käytetä
        </Heading>
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            <strong>Lomakkeilla</strong>, jos
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                tietoja ei julkaista Työmarkkinatorilla yleisesti nähtäviksi.
                Tällöin käytetään <strong>Tallenna</strong>-toimintoa.
              </ListItem>
              <ListItem>
                käyttäjä ei voi enää muokata tietoja lähettämisen jälkeen.
                Tällöin käytetään <strong>Lähetä</strong>-toimintoa.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <strong>Käsittelynäkymissä</strong>
          </ListItem>
        </List>
      </Message>
    </Section>
    <Section title="Periaatteet">
      <Heading
        icon={
          <Bullet
            color="success"
            icon={
              <ThumbUpIcon
                color="white"
                size="md"
              />
            }
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Tietojen muokkaaminen: kyllä
      </Heading>
      <List
        noMargin
        variant="unordered"
      >
        <ListItem>
          <strong>Käyttäjä voi muokata tietoja </strong> julkaisun jälkeen{' '}
          <strong>Muokkaa</strong>-toiminnolla.
        </ListItem>
        <ListItem>
          Käyttäjä voi poistaa julkaistut tiedot yleisesti nähtäviltä{' '}
          <strong>Palauta luonnokseksi</strong> tai <strong>Arkistoi</strong>
          -toiminnolla.
        </ListItem>
      </List>
      <Divider />
      <Heading
        icon={
          <Bullet
            color="success"
            icon={
              <ThumbUpIcon
                color="white"
                size="md"
              />
            }
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Tietojen validointi: kyllä
      </Heading>
      <List
        noMargin
        variant="unordered"
      >
        <ListItem>
          Lomakkeella syötetyt tiedot <strong>validoidaan aina</strong>{' '}
          toiminnon suorittamisen yhteydessä.
        </ListItem>
        <ListItem>
          Validointivirheistä ilmoitetaan käyttäjälle{' '}
          <Link page="Lomakkeen virheenkäsittely" /> -patternin mukaisesti
        </ListItem>
      </List>
      <Divider />
      <Heading
        icon={
          <Bullet
            color="success"
            icon={
              <ThumbUpIcon
                color="white"
                size="md"
              />
            }
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Toiminnon varmistus: kyllä
      </Heading>
      <List
        noMargin
        variant="unordered"
      >
        <ListItem>
          Toiminnon suorittaminen <strong>varmistetaan aina</strong> käyttäjältä
          varmistusdialogilla (
          <Code>
            <Link page="Modal" />
          </Code>
          )
        </ListItem>
      </List>
      <Divider />
      <Heading
        icon={
          <Bullet
            color="success"
            icon={
              <ThumbUpIcon
                color="white"
                size="md"
              />
            }
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Palaute toiminnon onnistumisesta: kyllä
      </Heading>
      <List
        noMargin
        variant="unordered"
      >
        <ListItem>
          Toiminnon onnistumisesta <strong>ilmoitetaan aina</strong> käyttäjälle
          väliaikaisella ilmoituksella (
          <Code>
            <Link page="Notifications" />, status='success'
          </Code>
          )
        </ListItem>
      </List>
      <Divider />
      <Heading
        icon={
          <Bullet
            color="success"
            icon={
              <ThumbUpIcon
                color="white"
                size="md"
              />
            }
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Palaute toiminnon epäonnistumisesta: kyllä
      </Heading>
      <List
        noMargin
        variant="unordered"
      >
        <ListItem>
          Toiminnon epäonnistumisesta <strong>ilmoitetaan aina</strong>{' '}
          käyttäjälle
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              <strong>
                Julkaisun yhteydessä ilmenneestä validointivirheestä
              </strong>{' '}
              ilmoitetaan näkymään lisättävällä varoituksella (
              <Code>
                <Link page="Alert" />
              </Code>
              ). Tarkempi ohjeistus <Link page="Lomakkeen virheenkäsittely" />{' '}
              -patternissa
            </ListItem>
            <ListItem>
              <strong>
                Julkaisun estävästä välittömästä järjestelmävirheestä
              </strong>{' '}
              ilmoitetaan varoitusdialogilla (
              <Code>
                <Link page="Modal" />
              </Code>
              ). Katso esimerkki kohdasta{' '}
              <a href="#tilat-ja-muunnelmat">Tilat ja muunnelmat</a>.
            </ListItem>
          </List>
        </ListItem>
      </List>
      <Divider />
      <Heading
        level={3}
        size={5}
      >
        Liittyvät toiminnot
      </Heading>
      <List variant="unordered">
        <ListItem>
          <strong>
            Tallentamisen vaihtoehtona tarjotaan aina jokin keskeyttävä
            toiminto:
          </strong>
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              <Link page="Jatka myöhemmin -toiminto" />
            </ListItem>
            <ListItem>
              <Link page="Keskeytä-toiminto" />
            </ListItem>
            <ListItem>Peruuta-toiminto</ListItem>
          </List>
        </ListItem>
        <ListItem>
          Julkaisun yhteydessä tarjotaan aina lisätoimintona{' '}
          <strong>Tallenna luonnoksena</strong> -toiminto
        </ListItem>
        <ListItem>
          Julkaisun yhteydessä voidaan tarvittaessa tarjota lisätoimintona{' '}
          <strong>Esikatsele</strong> -toiminto
        </ListItem>
      </List>
    </Section>
    <Section title="Rakenne ja toiminnallisuus">
      <Playground
        enableOverflow
        example={PatternExampleJulkaise}
      />

      <NoteItem
        bullet="1"
        title={
          <>
            Painike (
            <Code>
              <Link page="Button" />, color='secondary'
            </Code>
            )
          </>
        }
      >
        <Paragraph>
          Painikkeesta avautuu varmistusdialogi (
          <Code>
            <Link page="Modal" />
          </Code>
          ), josta käyttäjä hyväksyy tai peruuttaa toiminnon suorittamisen.
        </Paragraph>
      </NoteItem>
      <NoteItem
        bullet="2"
        title={
          <>
            Varmistusdialogi (
            <Code>
              <Link page="Modal" />
            </Code>
            )
          </>
        }
      >
        <List variant="unordered">
          <ListItem>
            <strong>Otsikko</strong>
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Varmistus toiminnon suorittamisesta kysymysmuodossa.
              </ListItem>
              <ListItem>
                Oletuksena kysymyksessä täsmennetään julkaistavan tiedon tyyppi.
                Esimerkiksi: &quot;Julkaistaanko työpaikkailmoitus?&quot;
              </ListItem>
              <ListItem>
                Kysymyksessä on hyvä mainita myös otsikko tai nimi, jonka
                käyttäjä on tiedoille antanut. Esimerkiksi: &quot;Julkaistaanko
                työpaikkailmoitus &quot;Keittiömestari&quot;?&quot;
              </ListItem>
              <ListItem>
                Geneeristä muotoilua &quot;Julkaistaanko tiedot?&quot;
                käytetään, jos tiedon tyyppiä ei ole mahdollista täsmentää.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <strong>Teksti</strong>
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Tarkentavaa lisätietoa toiminnon hyväksymisen yksityiskohdista
                ja seurauksista, esimerkiksi
              </ListItem>
              <ListItem>
                kenelle ja missä missä julkaistut tiedot näkyvät,
              </ListItem>
              <ListItem>
                mitä toimintoja tiedoille voi tehdä julkaisun jälkeen
              </ListItem>
              <ListItem>
                Esimerkiksi: &quot;Julkaistut tiedot näkyvät kaikille
                Työmarkkinatorin käyttäjille Avoimet työpaikat-sivulla. Voit
                muokata tietoja tai piilottaa ne muilta käyttäjiltä julkaisun
                jälkeenkin.&quot;
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <strong>Toiminnot</strong>
            <List variant="unordered">
              <ListItem>
                <strong>Peruuta</strong>-painike (
                <Code>
                  <Link page="Button" />, variant='outline'
                </Code>
                ).
                <List
                  noMargin
                  variant="unordered"
                >
                  <ListItem>
                    Sulkee dialogin ja palauttaa käyttäjän näkymään jossa hän
                    painoi toimintopainiketta.
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                <strong>Julkaise</strong>-painike (
                <Code>
                  <Link page="Button" />, color='secondary'
                </Code>
                ).
                <List
                  noMargin
                  variant="unordered"
                >
                  <ListItem>sulkee dialogin ja suorittaa toiminnon</ListItem>
                  <ListItem>
                    Käyttäjä ohjataan erikseen määriteltyyn näkymään.
                    Esimerkiksi lomakkeen lähettämisen jälkeen käyttäjä voidaan
                    ohjata näkymään, josta hän saapui lomakkeelle.
                  </ListItem>
                </List>
              </ListItem>
            </List>
          </ListItem>
        </List>
      </NoteItem>

      <NoteItem
        bullet="3"
        title={
          <>
            Ilmoitus toiminnon onnistumisesta (
            <Code>
              <Link page="Notifications" />, status='success'
            </Code>
            )
          </>
        }
      >
        <Paragraph noMargin>Ilmoituksen teksti voi olla</Paragraph>
        <List variant="unordered">
          <ListItem>
            geneerinen &quot;Tiedot julkaistiin onnistuneesti&quot;,
          </ListItem>
          <ListItem>
            tarkentaa tallennetun tiedon tyypin (esim. &quot;Työpaikkailmoitus
            julkaistiin onnistuneesti&quot;) tai
          </ListItem>
          <ListItem>
            tarjota lisäksi tarkempaa kontekstikohtaista tietoa, kuten
            julkaistun tiedon otsikon.
          </ListItem>
        </List>
      </NoteItem>
    </Section>
    <Section title="Tilat ja muunnelmat">
      <SectionHeading>Ilmoitus järjestelmävirheestä</SectionHeading>
      <Paragraph noMargin>
        Julkaisun estävästä välittömästä järjestelmävirheestä ilmoitetaan
        varoitusdialogilla (
        <Code>
          <Link page="Modal" />
        </Code>
        ).
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Modaalin otsikko on geneerinen: "Julkaisu epäonnistui"
        </ListItem>
        <ListItem>
          Modaalin teksti voi olla
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              geneerinen &quot;Tietojen julkaisu epäonnistui. Yritä
              uudelleen.&quot;,
            </ListItem>
            <ListItem>antaa tarkempaa tietoa epäonnistumisen syystä.</ListItem>
          </List>
        </ListItem>
        <ListItem>
          Sulje-toiminto palauttaa käyttäjän näkymään jossa hän painoi
          toimintopainiketta.
        </ListItem>
      </List>
      <Playground enableOverflow>{PatternExampleFailJulkaise()}</Playground>
    </Section>
    <Section title="Sijoittuminen näkymässä">
      <Paragraph noMargin>
        Lomakkeella painike sijoitetaan lähtökohtaisesti sisältöalueen loppuun
        yhdessä muiden toimintopainikkeiden kanssa.
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Jos näkymässä on useita koko näkymään liittyviä toimintoja,{' '}
          <strong>Julkaise sijoitetaan toiminnoista viimeiseksi.</strong>
        </ListItem>
        <ListItem>
          Sijoittelusta lomakkeella tarkemmin{' '}
          <Link page="Lomakkeen toiminnot" /> -patternissa.
        </ListItem>
      </List>
      <ZoomedExample>
        <Columns layout="single">
          <Main>
            <FormLayout>
              <FormLayoutItem>
                <FormGroup noMargin>
                  <Textarea label="Lomakekenttä" />
                </FormGroup>
              </FormLayoutItem>
            </FormLayout>
            <Spacer marginTop="xl">
              <Buttons align="center">
                <Button
                  iconLeft={<XIcon />}
                  variant="plain"
                >
                  Keskeytä
                </Button>
                <Button
                  color="primary"
                  variant="outline"
                >
                  Tallenna luonnoksena
                </Button>
                <Button
                  color="primary"
                  variant="outline"
                >
                  Esikatsele
                </Button>
                <Button color="secondary">Julkaise</Button>
              </Buttons>
            </Spacer>
          </Main>
        </Columns>
      </ZoomedExample>
    </Section>
    <Section title="Esimerkkejä käyttötapauksista">
      <SectionHeading>Työmarkkinatorilla</SectionHeading>
      <List variant="unordered">
        <ListItem>Henkilöasiakas: Työnhakuprofiilin julkaisu</ListItem>
        <ListItem>
          Työnantaja-asiakas: Työpaikkailmoitukset, Lisää uusi työpaikkailmoitus
          -lomake
        </ListItem>
        <ListItem>
          Yhteistyökumppani: Palveluni, Lisää uusi palvelu -lomake
        </ListItem>
      </List>
      <SectionHeading>Asiantuntijan työmarkkinatorilla</SectionHeading>
      <List variant="unordered">
        <ListItem>Palvelut: Lisää uusi palvelu -lomake</ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
